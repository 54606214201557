.boop-Overlay {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    animation: fadeInKey 0.3s ease-in-out;
    border: 10px solid white;
}

.boop-Overlay:active {
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;
    animation: fadeInKey 0.3s ease-in-out;
}

@media (hover: hover), (-ms-high-contrast: none) {
    .boop-Overlay:hover {
        opacity: 0.75;
        transition: opacity 0.3s ease-in-out;
        animation: fadeInKey 0.3s ease-in-out;
    }
}

@keyframes fadeInKey {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.75;
    }
}
