.comp-Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - env(safe-area-inset-bottom));
    background: rgb(white);
}

.comp-Loader svg {
    width: auto;
    height: calc(10vh - env(safe-area-inset-bottom));
    filter: drop-shadow(3px 2px 1px rgb(0 0 0 / 0.4));
}
