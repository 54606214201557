.img-effect-wrapper {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-effect-container {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.img-effect-container img {
    top: 0;
}
