:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.App {
    text-align: center;
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/**
* Fixing UI Bugs e.v. navbar
*
*/

button:focus {
    outline: none;
    outline: none -webkit-focus-ring-color;
}

.navbar > .container {
    max-width: 100%;
    margin: 0;
    padding: 0;
}

.navbar {
    margin: 0;
    padding: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

#logoImage {
    background-color: red !important;
    object-fit: contain;
    max-width: 100px;
    height: auto;
    aspect-ratio: 1;
}

main {
    color: #fffce1;
    /* padding-top: 10em; */
    padding-bottom: 0;
    text-align: center;
    background: white;
    /* box-shadow: 0 3px 3px rgba(0,0,0,0.2); */
    position: relative;
    z-index: 2;
    margin-bottom: var(--footer-height, 700);
    /* width: 100vw; */
    /* overflow-x: hidden !important; */
}

.container-xxl {
    max-width: 2500px !important;
}
html {
    overflow: auto;
    overflow-x: hidden !important;
}

body {
    overflow: auto;
    overflow-x: hidden !important;
}

/* ::-webkit-scrollbar {
    display: none;
} */

@media screen and (orientation: landscape) {
    .main-content {
        display: none;
    }
    .use-portrait {
        display: block;
    }
}

.list-group-item {
    background-color: transparent !important;
    border: none !important;
}

.ReactModal__Overlay {
    z-index: 1000;
}

img {
    color: black;
    display: flex;
    align-self: center;
    user-select: none;
    user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}



.css-100qw9n {
    height: 100%;
    display: inline-block;
}

video {
    object-fit: fill;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Edge, IE */
    user-select: none; /* Modern browsers */
}

.monitor_button {
    background: white;
}

Reveal {
    display: flex !important;
    width: inherit;
    height: inherit;
}
