.navbar-nav {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.collapsing {
    -webkit-transition: none;
    transition: none;
}

.navbar-brand {
    margin-right: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.navbar-toggler {
    padding: 0 !important;
}

.navbar {
    --bs-navbar-padding-y: 0.2rem !important;
    padding: 0 !important;
}

.list-group-item {
    align-self: left;
    background-color: transparent;
}

.headerBottom {
    z-index: 10;
    position: absolute;
    /* transform: translate(-50%, -50%); */
    bottom: 5vh;
    right: 50px;
    text-align: left;
    width: fit-content;
    padding: 2px;
}
.headerBottom .headerList {
    position: absolute;
    bottom: 0;
    display: contents;
    font-size: 20vw;
    color: black;
    z-index: 3;
    font-size: 3vh !important;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    font-weight: 500;
    font-family: Lato, Geneva, sans-serif;
    word-spacing: 7.5pt;
    letter-spacing: 0.02em;
    margin: auto;
    color: white;
    animation: anim-lineUp ease-out 1.5s;
}

.headerLink {
    text-decoration: none !important;
    font-family: 'PT Serif', serif;
    font-weight: 500;
    letter-spacing: 2pt;
    line-height: 110%;
    word-spacing: 2pt;
    font-size: calc(22pt + (5vh - 5.5vw) / 5.5);
    color: white;
    display: block;
}

.headerLink:hover {
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.headerLinkToggle {
    text-decoration: none !important;
    font-family: 'PT Serif', serif;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 150%;
    word-spacing: 2pt;
    font-size: 4vh;
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    display: contents;
}

.headerLink span {
    text-decoration: none !important;
    color: black !important;
}

.headerTitle {
    font-family: le-monde-livre-classic-byol, serif;
    font-weight: 300;
    word-spacing: 2pt;
    letter-spacing: 0.02em;
    margin-bottom: 0;
}

.offcanvas_Main {
    padding-top: 0;
    background-color: black;
    z-index: 5001;

    /*transition:all .45s ease-in-out; */
}
.offcanvas-header {
    padding: 0 !important;
}

.offcanvas_Header {
    height: 60px;
    width: 100%;
    position: relative;
    margin-top: 2vh;
}

.offcanvas_Header_D {
    margin-top: 30pt;
    height: 60px;
    width: 100vw;
    position: relative;
}

.collapsing {
    transition: none !important;
}

.offcanvas_Container {
    padding-left: 0px !important;
    position: relative;
    margin-left: 0px !important;
    width: 100vw !important;
}

.offcanvas_Header .offcanvas_Header_Text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.offcanvas_Header .offcanvas_Header_Toggle {
    display: flex;
    justify-content: flex-start; /* EDITED from start */
    align-items: center;
    position: absolute;
    height: 100%;
    /* margin-left: calc(2vh + 7.5px); */
    /* margin-left: 35pt; */
}

.offcanvas_Header_D .offcanvas_Header_child {
    align-self: center;
    justify-self: center;
    line-height: 40px;
}

.offcanvas_Header_D .offcanvas_Header_Text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.offcanvas_Header_D .offcanvas_Header_Toggle {
    display: flex;
    justify-content: flex-start; /* EDITED from start */
    align-items: center;
    position: absolute;
    color: white !important;
    height: 100%;
    margin-left: 40pt;
}

.offcanvas_Header_D .offcanvas_Header_child {
    align-self: center;
    justify-self: center;
    line-height: 40px;
}

.offcanvas.offcanvas-start {
    width: 100%;
}

/* .offcanvas-backdrop.show {
  height: 100%;
  overflow: hidden;
  position: fixed;
  display: block;
} */

.collapsing {
    transition: none;
}

.offcanvas {
    border-right-width: 0 !important;
}

.offcanvas_body {
    display: flex;
    justify-content: center;
}

.offcanvas_body_Mobile {
    padding-top: 7.5vh;
    margin-bottom: 15vh;
}

.offcanvas_body_Desktop {
    padding-top: 15vh;
    width: 50%;
}

@media only screen and (orientation: landscape) {
    .offcanvas_body_Mobile {
        padding-top: 0;
    }
}

.header {
    z-index: 1030;
    top: 0;
    margin-top: 15pt;
    width: 100%;
    background-color: transparent;
    position: -webkit-sticky;
    position: absolute;
}

.toggler {
    -moz-transition: color, 0.6s ease-in-out;
    -ms-transition: color, 0.6s ease-in-out;
    -o-transition: color, 0.6s ease-in-out;
    transition: color, 0.6s ease-in-out;
}

img {
    transition: opacity 0.4s ease-in;

    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    opacity: 1;
}
.hidden {
    transition: opacity 0.4s ease-out;

    -moz-transition: opacity 0.4s ease-out;
    -ms-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
    opacity: 0;
}

.header-D {
    z-index: 1030;
    top: 0;
    margin-top: 30pt;
    width: 100%;
    background-color: white;
    position: -webkit-sticky;
    position: absolute;
}
.headerHome-D {
    z-index: 1030;
    top: 0;
    padding-top: 30pt;
    width: 100%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
}

video::-webkit-media-controls {
    display: none;
}

/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-start-playback-button {
    display: none;
}

video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
}

@media (min-width: 1400px) {
    .container {
        max-width: 3440px !important;
    }
}

.bg-textAnim {
    display: inline-block;
    content: '';
    padding-bottom: 46pt;
    font-size: large;
    height: 2px !important;
}
.bg-textAnim:hover {
    color: white;
    padding-bottom: 46pt;
}
.bg-textAnim:after {
    content: '';
    display: block;
    height: 2px;

    width: 0;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
    float: right;
}
.bg-textAnim:hover:after {
    width: 100%;

    content: '';
    background: white;
    height: 2px;
    transition: width 0.5s ease, background-color 0.5s ease;
    float: left;
}

.headerLink.sm-textAnim {
    color: white !important;
}
.headerLink.sm-textAnim:hover {
    color: rgba(255, 255, 255, 0.6) !important;
    text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
}

.headerLinkSM-D.sm-textAnim:hover {
    color: white !important;
}
.headerLinkSM-D.sm-textAnim {
    color: rgba(255, 255, 255, 0.6) !important;
}

.headerLinkSM-M.sm-textAnim:hover {
    color: white !important;
}
.headerLinkSM-M.sm-textAnim {
    text-align: center;
    color: rgba(255, 255, 255, 0.6) !important;
}

.headerLinkLM.lg-textAnim {
    text-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
    display: inline-block;
    content: '';
    height: 2px;
    color: white;
    font-weight: 500;
    font-family: 'Raleway', Lato, Geneva, serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
}

.headerLinkLM.lg-textAnim::before {
    color: white;
    background: white;
    height: 2.5px;
    bottom: calc(-25pt);
    position: absolute;
    width: 100%;
    display: inline-block;
    content: '';

    -moz-transition: width 0.5s ease, 0.5s ease-out;
    -o-transition: width 0.5s ease, 0.5s ease-out;
    -ms-transition: width 0.5s ease, 0.5s ease-out;
    transition: width 0.5s ease, 0.5s ease-out;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-2 12:1:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-right
 * ----------------------------------------
 */
@keyframes tracking-in-expand-fwd-right {
    0% {
        transform: translateZ(-200px) translateX(400px);
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        transform: translateZ(-200px) translateY(600px);
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.headerLinkLM.lg-textAnim:after {
    content: '';
    display: block;
    height: 2.5px;
    width: 100%;
    bottom: calc(-25pt);
    position: absolute;
    background: transparent;
    clip-path: inset(0 0 0 100%);

    transition: background-color 0.5s ease;

    -moz-transition: width 0.5s ease, 0.5s ease-out;
    -o-transition: width 0.5s ease, 0.5s ease-out;
    -ms-transition: width 0.5s ease, 0.5s ease-out;
    transition: width 0.5s ease, 0.5s ease-out;
    float: left;
}
.headerLinkLM.lg-textAnim:hover:after {
    content: '';
    display: block;
    height: 2.5px;
    width: 100%;
    bottom: calc(-25pt);
    position: absolute;
    clip-path: inset(0 0 0 0%);
    background: white;

    -moz-transition: width 0.5s ease, 0.5s ease-out;
    -o-transition: width 0.5s ease, 0.5s ease-out;
    -ms-transition: width 0.5s ease, 0.5s ease-out;
    transition: background-color 0.5s ease;

    transition: width 0.5s ease, 0.5s ease-out;
    transition-delay: 400ms;
    float: left;
}

.headerLinkLM.lg-textAnim:hover::before {
    width: 0%;
    content: '';
    background: white;
    height: 2.5px;
    transition: background-color 0.5s ease;

    -moz-transition: width 0.5s ease-out;
    -o-transition: width 0.5s ease-out;
    -ms-transition: width 0.5s ease-out;
    transition: width 0.5s ease-out;
    float: left;
}

.slide {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 10;
    display: block;
    margin: auto;
    animation-name: slide;
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes slide {
    0% {
        transform: translateX(0vw);
    }

    /* Fourth second. */
    100% {
        transform: translateX(50vw);
    }
}

.imageLogoFadeInOut {
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

#imageWhite {
    filter: drop-shadow(5px 3px 3px rgba(50, 50, 50, 0.4));
}

.imageSpanWrapper {
    width: 100vw;
    display: flex;
    justify-items: center;
    justify-content: center;
}
.imageDivWrapper {
    width: 55px;
}

.headerLinkListItem {
    border: none !important;
    animation: tracking-in-expand-fwd-right 1s cubic-bezier(0.3, 1, 0.9, 1) both;
    animation-delay: 1000ms;
}

.headerLinkListItem.invertedLinkM {
    border: none !important;
    animation: tracking-in-expand-fwd-bottom 1s cubic-bezier(0.3, 1, 0.9, 1) both;
    animation-delay: 0.5s;
}

.headerLinkSM-D {
    text-decoration: none !important;
    font-family: 'Raleway', Lato, Geneva, serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
    font-weight: 400;
    letter-spacing: 2pt;
    word-spacing: 2pt;
    font-size: calc(6pt + 0.75vw);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    display: block;
    left: +10px;
}

.headerLinkSM-M {
    text-decoration: none !important;
    font-family: 'Raleway', Lato, Geneva, serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
    font-weight: 400;
    letter-spacing: 2pt;
    word-spacing: 2pt;
    font-size: calc(6pt + (8vh - 0.5vw) / 8);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    /* display: none; */
    width: auto;
}

.headerLinkLM {
    text-decoration: none !important;
    font-family: 'Raleway', Lato, Geneva, serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
    font-weight: 400;
    height: fit-content;
    letter-spacing: 0.02em;
    line-height: 20pt;
    word-spacing: 2pt;
    font-size: 22pt;
    color: white;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    display: block;
}

.headerLinkSM-D .subelement {
    margin-top: 0.5vh;
    margin-left: 1vh;
}

@media only screen and (orientation: portrait) {
    .headerLinkSM-M {
        /* display: block; */
    }
}

.offcanvas-infoText {
    font-size: calc(6pt + 0.75vw);
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    word-spacing: 0.5pt;
    letter-spacing: 0.02em;
    display: flex;
    height: 100%;
    align-items: center;
}

.offcanvas-infoText:hover {
    color: white !important;
}

.offcanvas-infoText_Mobile {
    font-size: calc(6pt + 1vh);
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    word-spacing: 0.5pt;
    letter-spacing: 0.02em;
}

.offcanvas-ListItem_Mobile {
    display: none;
}

@media only screen and (orientation: landscape) {
    .offcanvas-contact_Mobile {
        margin-bottom: 10vh;
    }
}

@media only screen and (orientation: portrait) {
    .offcanvas-ListItem_Mobile {
        text-align: center;
        display: block;
    }
}

.offcanvas-infoText_Mobile:hover {
    color: white !important;
}

.previewNavImage-Wrapper {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    /* padding-bottom: 15vh; */
    overflow: hidden;
    z-index: 5;
}

.previewNavImage-Wrapper .contactDetails {
    position: absolute;
    height: 15%;
    width: 100%;
    bottom: 0;
}

.previewNavImage {
    z-index: 0;
    position: relative;
    height: 100%;
    min-width: 120%;
    object-fit: cover;
    top: 0;
    object-position: center;
}

.imagePanWrapper {
    position: fixed;
    height: calc(100% - 15vh);
    width: 50%;
    top: 0;
    right: 0;
    overflow: hidden;

    z-index: 5;
    display: flex;
    justify-content: center;
}

/* Desktop */
.logo-D-image {
    height: 50pt;
    width: auto;
    margin-bottom: 15;
    margin-top: 15;
    aspect-ratio: 0.935;
    display: flex;
    position: absolute;
}

.logo-D-toggle {
    position: absolute;
    margin-top: 30pt;
    border: none;
}

.logo-D-menu {
    margin-left: 35pt;
}

.logo-D-offcanvasMenu {
    margin-left: 4vw;
}

/* Mobile */

.logo-M-image {
    height: 40pt;
    width: auto;
    margin-bottom: 10;
    margin-top: 10;
    aspect-ratio: 0.935;
    display: block;
    justify-content: center;
    position: absolute;
}

.logo-M-toggle {
    position: absolute;
    margin-top: 4.5em;
    border: none;
}

.logo-M-menu {
    margin-left: 3vw;
}

.logo-M-offcanvasMenu {
    margin-left: 4vw;
}
