:root {
    --color-scheme-active: rgba(0, 0, 0, 1);
    --color-scheme-inactive: rgba(0, 0, 0, 0.7);
    --color-scheme-subactive: rgba(0, 0, 0, 0.4);
}

.sepLinkListItem {
    width: 200px;
}

.lg-button {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 16px;
}

.lg-button .roundButton {
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    display: block;
    color: black;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 14pt;
    transition: all 300ms ease;
}

.lg-button .roundButton:hover {
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    display: block;
    color: white;
    background-color: #222529;
    padding: 0.3em;
    border-radius: 14pt;
    box-shadow: 0pt 0pt 10pt rgba(0, 0, 0, 0.3);
    transform: scale(1.3);
    transform-origin: center;
    transition: all 300ms ease;
}

.lg-button .roundButton.invertedLinkM {
    width: fit-content;
    margin: 5px auto;
    padding: 0 7pt 0 10pt;
    background-color: rgba(140, 140, 140, 0.7);
    transition: all 300ms ease;

    box-shadow: 2pt 4pt 6pt rgba(0, 0, 0, 0.2);
}

.lg-button .roundButton:hover.invertedLinkM {
    width: fit-content;
    margin: 5px auto;
    padding: 0 7pt 0 10pt;
    background-color: rgba(100, 100, 100, 0);
    border-color: transparent;
    box-shadow: none;
    transition: all 300ms ease;
}

.lg-button .roundButton:hover .roundButtonInsideText {
    color: white;
    transition: all 300ms ease;
}

.lg-button .roundButton:hover .outlineArrowRight {
    transition: all 300ms ease;

    color: white;
}

.lg-button .roundButton .roundButtonInsideBtn {
    width: fit-content;
    position: relative;
    display: block;
    text-align: center;
    margin: auto;
    margin-right: 0.75em;
    padding: 6pt 8pt 6pt 5pt;
    font-size: 10pt;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 120%;
    border-radius: 14pt;
    background: -webkit-linear-gradient(transparent, rgba(0, 2, 2, 0.8));
    font-family: 'Raleway', sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
}

.lg-button .roundButton .roundButtonInsideText {
    position: relative;
    display: block;

    margin-left: 0.5em;
    color: black;
    letter-spacing: 0.02em;
    border-radius: 14pt;
    line-height: 120%;
    font-size: 18pt;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
}

.lg-button .roundButton.invertedLinkM .roundButtonInsideText {
    margin-left: 0;
    color: white;
    font-size: 16pt;
    font-weight: 600;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
    padding: 5pt 0;
}

@media only screen and (orientation: landscape) {
    .lg-button .roundButton.invertedLinkM .roundButtonInsideText {
        font-size: 14pt;
        font-weight: 700;
    }
    .lg-button .roundButton .roundButtonInsideText {
        padding: 10pt 0;
    }
    .lg-button .roundButton.invertedLinkM {
        padding: 0 10pt 0 10pt;
    }
    .contentFooter-M {
        height: initial;
    }
}

.lg-button .roundButton .outlineArrowRight {
    color: black;
    margin: 0 0.25em 0 0.25em;
}

.requestButton {
    padding: 13px 50px;
    border-radius: 60px;
    letter-spacing: 0.02em;
    line-height: 100%;
    font-size: medium;
    max-width: 250px;
}
@media screen and (max-width: 991px) and (orientation: landscape) {
    .requestButton {
        padding: 13px 0;
        width: 100%;
        font-size: medium;
    }
}

.infoButton {
    padding: 13px 50px;
    border-radius: 60px;
    letter-spacing: 0.02em;
    line-height: 100%;
    font-size: 13pt;
}

.flatLinkButton {
    padding: 10px 20px;
    border-radius: 60px;
    color: black;
}

.flatLinkButton:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 0, 0, 0.3);
}

.linkButton {
    margin-top: 3vh;
    padding: 13px 25px;
    letter-spacing: 0.02em;
    line-height: 150%;
    font-size: 15pt;
    border-radius: 30px;
}

@media only screen and (orientation: portrait) and (max-width: 991px) {
    .linkButton {
        font-size: 12pt;
        padding: 10px 15px;
    }
}

@media only screen and (orientation: portrait) and (max-width: 991px) {
    #contact_button {
        font-size: 15pt;
        padding: 10px 15px;
    }
}
@media only screen and (orientation: landscape) and (max-width: 991px) {
    .linkButton {
        font-size: 10pt;
    }
}

.linkButton:hover {
    background-color: rgb(234, 234, 233, 0.4);
    color: black;
}

.flatLevelButton {
    width: 150pt;
    padding: 13px 18px;
    letter-spacing: 0.02em;
    font-size: 15pt;
    font-weight: 500;
    border-radius: 60px;
}

@media only screen and (orientation: landscape) {
    .flatLevelButton.Mobile {
        font-size: 12pt;
    }
}

.flatLevelButton.btn-lg:active {
    background-color: var(--color-scheme-subactive);
    color: rgba(0, 0, 0, 0.5);
}

.flatLevelButton:hover {
    background-color: var(--color-scheme-inactive);
    color: rgba(0, 0, 0, 1);
}

.flatLevelButton.selected {
    background-color: var(--color-scheme-active);
    color: white;
    opacity: 1;
}

.flatLevelButton.selected.btn-lg:hover {
    background-color: var(--color-scheme-active);
    opacity: 0.8;
    color: white;
}

.flatContactButton.btn-lg {
    width: fit-content;
    max-width: 200pt;
    padding: 10pt 20pt;
    border: 1.5px solid black;
    border-radius: 60px;
    background-color: var(--color-scheme-active);
    color: white;
    letter-spacing: 0.02em;
    line-height: 150%;
    font-size: 15pt;
    font-weight: 500;
    margin-top: 2em;
}

.flatContactButton.Mobile {
    padding: 7.5pt 12.5pt;
    margin-top: 1em;
}

@media only screen and (orientation: landscape) {
    .flatContactButton.Mobile {
        font-size: 12pt;
    }
}

.flatContactButton.btn-lg:active {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0.8;
    border-color: black;
    color: black;
}

.flatContactButton.btn-lg:hover {
    background-color: var(--color-scheme-inactive);
    color: white;
}

.mdArrow {
    display: inline-flex;
    margin: auto;
    color: white;
}

.modularFlatList-ButtonWrapper {
    display: block;
    justify-content: space-between;
    width: 100%;
}

.flatListB-Desktop {
    cursor: pointer;
    min-width: 0;
    max-width: 500px;
    width: 100%;
    overflow: hidden;
    padding: 0;
    border-radius: 7pt;
    border-color: rgba(0, 0, 0, 0.5);
    background-color: #4c6d4e; /* whitesmoke; */
    color: white;
    .mdArrow {
        color: white;
    }
    &:hover .mdArrow {
        color: white;
        transition: all 300ms ease;
    }
}
.flatListB-Desktop.unavailable {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    padding: 0;
    border-radius: 7pt;
    border-color: rgba(0, 0, 0, 0.4);
    background-color: rgba(146, 146, 146, 0.6); /* whitesmoke */
    color: black;
    .mdArrow {
        color: black;
    }
}

.flatListB-Mobile {
    width: 100%;
    min-width: 350px;
    max-width: 80vw;
    overflow: hidden;
    padding: 0;
    border-color: rgba(0, 0, 0, 0.5);
    border-radius: 7pt;
    background-color: #4c6d4e; /* whitesmoke; */
    color: white;
    & .mdArrow {
        color: white;
    }
    &:hover .mdArrow {
        color: white;
        transition: all 300ms ease;
    }
}

.flatListB-Mobile.unavailable {
    width: 100%;
    overflow: hidden;
    padding: 0;
    border-radius: 7pt;
    border-color: rgba(0, 0, 0, 0.4);
    /* background-color: whitesmoke; */
    background-color: rgba(146, 146, 146, 0.6);
    color: black;
    & .mdArrow {
        color: black;
        transition: all 300ms ease;
    }
}

.flatListB-Mobile {
    &.btn.btn-outline-dark:hover {
        background: #9bb899;

        color: #212529;
        transition: all 300ms ease;
    }
    &:hover .flatListCB .mdArrow {
        color: #212529;
        transition: all 300ms ease;
    }
    &.btn.btn-outline-dark:active {
        background: #9bb899;
        color: white;
        transition: all 300ms ease;
    }
    &:active .flatListCB .mdArrow {
        color: white;
        transition: all 300ms ease;
    }
}

.flatListB-Mobile.unavailable {
    &.btn.btn-outline-dark:hover {
        background: black;

        color: white;
        transition: all 300ms ease;
    }
    &:hover .flatListCB .mdArrow {
        color: white;
        transition: all 300ms ease;
    }
    &.btn.btn-outline-dark:active {
        background: black;
        color: white;
        transition: all 300ms ease;
    }
    &:active .flatListCB .mdArrow {
        color: white;
        transition: all 300ms ease;
    }
}

@media (hover: hover), (-ms-high-contrast: none) {
    .flatListB-Desktop {
        &.btn.btn-outline-dark:hover {
            /* background: rgb(20,20,20); */
            background: #9bb899;
            color: black;
            transition: all 300ms ease;
        }
        &.mdArrow {
            color: black;
            transition: all 300ms ease;
        }
        &:hover .mdArrow {
            color: black;
            transition: all 400ms ease;
        }
        &:hover .levelAdText {
            color: black;
            transition: all 300ms ease;
        }
    }

    .flatListB-Desktop.unavailable {
        &.btn.btn-outline-dark:hover {
            background: rgb(20, 20, 20);
            color: white;
            transition: all 300ms ease;
        }
        &.mdArrow {
            color: white;
            transition: all 300ms ease;
        }
        &:hover .mdArrow {
            color: white;
            transition: all 400ms ease;
        }
        &:hover .levelAdText {
            color: white;
            transition: all 300ms ease;
        }
    }
}

.flatListCB {
    width: 100%;

    display: grid;
    align-self: center;
    margin: 5pt;
}

.cbPadding-Mobile {
    width: fit-content;
    padding: 10pt 10pt 10pt 10pt;
}

.cbPadding-Desktop {
    width: fit-content;
    padding: 10pt 0 10pt 10pt;
}

.btn-outline-dark {
    transition: all 300ms ease;

    border-width: 1.5px;
}

.btn-dark {
    border-width: 2px;
}
.btn-outline-light {
    border-width: 2px;
}
.flatListWrap {
    height: fit-content;
    display: flex;
}

.flatListMB {
    width: 100%;
    position: relative;

    border-radius: 7pt;
    border-color: rgba(0, 0, 0, 0.5);
}

.backToTopButton {
    z-index: 1200;
    height: 50px;
    width: 50px;
    position: fixed;
    opacity: 0;
    visibility: 0;
    display: hidden;
    bottom: 50px;
    right: 40px;
    margin: 0px;
    padding: 0px;
    background-color: #eaeae99b;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    border-width: 0;
}

.backToTopButton:hover {
    background-color: #d1d1d1;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    transition: 0.15s linear;
}

.backToTopButton:hover .hiArrowUp {
    height: 35px;
    width: 35px;
    transition: 0.05s linear;
}

.compareZoomButton {
    color: #d3d3ce;
    display: flex;
    position: relative;
    padding: 7.5vh 0 0 0;

    filter: drop-shadow(1px 1px 2px rgba(50, 50, 50, 0.7));
    &.right {
        margin-left: 30pt;
        justify-content: start;
    }
    &.left {
        margin-right: 30pt;
        justify-content: end;
    }
}

.compareZoomButton > svg {
    transform: scale(1);
    -webkit-transition: all 0.4s ease !important;
    -moz-transition: all 0.4s ease !important;
    -o-transition: all 0.4s ease !important;
    -ms-transition: all 0.4s ease !important;
    transition: all 0.4s ease !important;
}
.compareZoomButton > svg:hover {
    transform: scale(1.3);
    cursor: pointer;
}
